<template>
  <PanelMolecule :title="title" :hasMargin="true">
    <template slot="main">
      <TabRoutesMolecule :routes="routes" />
      <TalpaLoaderWrapper v-if="$apollo.loading" />
      <ErrorAtom v-else-if="error" :error="error" />
      <router-view :productId="productId" :activeLanguageId="activeLanguageId" @set-active-language-id="setActiveLanguageId" />
    </template>
  </PanelMolecule>
</template>

<script>
import { TalpaLoaderWrapper, ErrorAtom } from '@common/components'
import PanelMolecule from '@/components/Atomic/Molecules/PanelMolecule'
import TabRoutesMolecule from '@/components/Atomic/Molecules/TabRoutesMolecule'

import PRODUCT_QUERY from '#/graphql/marketplace/productQuery.gql'

export default {
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    PanelMolecule,
    TabRoutesMolecule,
  },
  data() {
    return {
      product: null,
      error: null,
    }
  },
  computed: {
    productId() {
      return this.$route.params.productId
    },
    activeLanguageId() {
      return this.$route.query?.language ?? 'EN'
    },
    title() {
      if (!this.product) {
        return ''
      }
      return `${this.product?.type} - ${this.product.name}`
    },
    routes() {
      // const firstTierLevelInProduct = this.product?.tierLevels.find(f => f.tierLevel === 1)
      return [
        {
          label: 'Overview',
          to: {
            name: 'EditProduct',
          },
        },
        {
          label: 'Tier levels',
          to: {
            name: 'EditProductTierLevels',
          },
          // isForcedActive: this.$route.name === 'CreateProductTierLevel' || this.$route.name === 'EditProductTierLevel',
          // to: firstTierLevelInProduct
          //   ? {
          //       name: 'EditProductTierLevel',
          //       params: {
          //         productId: this.productId,
          //         tierLevelId: firstTierLevelInProduct.id,
          //       },
          //     }
          //   : {
          //       name: 'CreateProductTierLevel',
          //       params: {
          //         productId: this.productId,
          //       },
          //     },
        },
        {
          label: 'Acquisitions',
          to: {
            name: 'EditProductAcquisitions',
          },
        },
      ].map(route => {
        return {
          id: route.label.toLowerCase(),
          label: route.label,
          isForcedActive: route.isForcedActive ?? false,
          to: {
            ...route.to,
            query: {
              ...this.$route.query,
            },
          },
        }
      })
    },
  },
  methods: {
    setActiveLanguageId(id) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route?.query,
          language: id === 'EN' ? undefined : id,
        },
      })
    },
  },
  apollo: {
    product: {
      query: PRODUCT_QUERY,
      variables() {
        return {
          where: {
            id: this.productId,
          },
        }
      },
      error(err) {
        this.error = err
      },
      update(data) {
        return data.product
      },
      skip() {
        return !this.productId
      },
    },
  },
}
</script>
