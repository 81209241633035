var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PanelMolecule', {
    attrs: {
      "title": _vm.title,
      "hasMargin": true
    }
  }, [_c('template', {
    slot: "main"
  }, [_c('TabRoutesMolecule', {
    attrs: {
      "routes": _vm.routes
    }
  }), _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _vm.error ? _c('ErrorAtom', {
    attrs: {
      "error": _vm.error
    }
  }) : _vm._e(), _c('router-view', {
    attrs: {
      "productId": _vm.productId,
      "activeLanguageId": _vm.activeLanguageId
    },
    on: {
      "set-active-language-id": _vm.setActiveLanguageId
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }